<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"

    >
<div>
  

        <b-row>

        <b-card class="mb-1 col-md-12" :title="'Scan '+scan.name+' (#'+scan.id+')'">
          <b>Client name :</b> {{scan.user.name}}<br />
            <b>Scan date :</b> {{scan.date}}<br /><br />
          <scan-viewer v-if="scan" :scan="scan" />
          <a target="_blank" v-if="isAdmin" :href="'/scans/review/'+scan.id">
              <b-button
                
                variant="dark"
              >
                Voir fiche
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.measures_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download measures
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.point_cloud_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download FacePointCloud
            </b-button>
            </a>
             <br /><br />
            <a target="_blank" :href="scan.unprojected_point_cloud_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download Unprojected PointCloud
            </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.unfiltered_point_cloud_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download Unfiltered PointCloud
            </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.debug_points_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download Debug Points
            </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.measure_points_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download MeasurePoints
            </b-button>
            </a>
            <br /><br />
            <a target="_blank" v-if="scan.basepoints_url!=null" :href="scan.basepoints_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download Basepoints
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" v-if="scan.screenshot0_url!=null" :href="scan.screenshot0_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download screenshot0
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" v-if="scan.screenshot1_url!=null" :href="scan.screenshot1_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download screenshot1
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" v-if="scan.screenshot2_url!=null" :href="scan.screenshot2_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download screenshot2
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" v-if="scan.screenshot3_url!=null" :href="scan.screenshot3_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download screenshot3
              </b-button>
            </a>
            
            
            <br /><br />
            
            <b-button v-if="selectedScan && selectedScan==scan.id"
              
              variant="success"
            >
              Selected for order
            </b-button>
            <b-button v-else @click="selectScan(scan.id)"
            
              variant="dark"
            >
              Use scan for order
            </b-button>
            &nbsp;
            <b-button @click="selectScan(scan.id)"
         
              variant="dark"
            >
              Delete Scan
            </b-button>
        </b-card>
   

  </b-row>

  <b-row>
      
        <b-card class="mb-1 col-md-4" v-if="!scan" :title="'Scan'">
          <b-card-text>
            <b>Client name :</b> <br />
            <b>Scan date :</b> <br /><br />

            <a target="_blank" download="true">
              <b-button
                
                variant="dark"
              >
                Download measures
              </b-button>
            </a>
            <br /><br />
            <a target="_blank"  download="true">
            <b-button
             
              variant="dark"
            >
              Download FacePointCloud
            </b-button>
            </a>
          </b-card-text>

          <br />
          <table class="table">
            
          </table>
          
           
            <b-button
            
              variant="dark"
            >
              Use scan for order
            </b-button>
            &nbsp;
            <b-button 
         
              variant="dark"
            >
              Delete Scan
            </b-button>
        </b-card>

        </b-row>
  
</div>
</b-overlay>
</template>

<script>
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce, Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'
import ScanCanvas from './ScanCanvas.vue'
import ScanViewer from './ScanViewer.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup,
    ScanCanvas,
    ScanViewer
  },
  data() {
    return {
      scan:null,
      isAdmin:false,
      selectedScan:localStorage.senseye_b2b_selected_scan,
      loading:true,
      measurements:null
    }
  },
  
  methods:{
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    async deleteScan(id)
    {
      
      this.loading=true
      await APIRequest.delete('scans',id)
      if(this.selectedScan) {
        if(this.selectedScan==id) {
          localStorage.senseye_b2b_selected_scan=null
        }
      } 
      this.$router.push({name:'scans'})
      },
    async getScan() {
      let scan=await APIRequest.detail('scans',this.$route.params.id)
      this.scan={}
      this.scan=scan

      if(scan.measurements)
      {
        let measurements=scan.measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        
    
        this.measurements=JSON.parse(measurements)
      }
      


      //console.log(measurements)
      //import data from "/Users/Documents/workspace/test.json"
    },
    selectScan(id) {
      this.selectedScan=this.scan.id
      localStorage.senseye_b2b_selected_scan=this.scan.id
   
    },
    
    
  },
  watch:{
    
  },
  async mounted()
  {
    this.loading=true
    let user=this.parseJwt(localStorage.token_senseye_auth);
    let user_data=await Auth.get_user(user.username)
    let is_admin=false
    let roles = user_data.roles
    console.log('test')
    console.log(roles)
    if(roles!=null)
    {
      for(let i=0;i<roles.length;i++) {
        if(roles[i]=="ROLE_ADMIN" || roles[i]=="ROLE_MAIN" || user_data.id==690 || user_data.id==927 ) {
          is_admin=true
        }
      }
    } 
    this.isAdmin=is_admin
   
    await this.getScan()
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.overlay-scan {
  width:100%;
  height:100vh;
  z-index:999999;
}
</style>
